import { Api } from './Api';
import { Sale, Sales } from '../models/Sale';
import CustomError from '../models/CustomError';
import { Income } from '@/classes/models/Income';

export class SaleApi extends Api {
  async fetchAll(page: number, pageSize: number): Promise<Sales> {
    let sales: Sale[] = [];
    try {
      const result = await this.fetchAllProtected<Sales>('docs/sale', {
        params: { page, pageSize },
      });
      if (result?.results?.length > 0) {
        sales = Sale.createFromArray(Sale, result.results);
      }
      return {
        ...result,
        results: [...sales],
      };
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async fetchById(id: number): Promise<Sale> {
    try {
      const result = await this.fetchByIdProtected<Sale>('docs/sale', id);
      if (result) {
        return Sale.createFromObject(Sale, result);
      }
      return new Sale({});
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async send(data: Sale): Promise<Sale> {
    try {
      const result = await this.sendProtected<Sale>('docs/sale', data);
      return Sale.createFromObject(Sale, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async update(data: Sale): Promise<Sale> {
    try {
      const result = await this.updateProtected('docs/sale', data);
      return Sale.createFromObject(Sale, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async remove(id: number) {
    try {
      await this.removeProtected('docs/sale', id);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async post(id: number): Promise<Income> {
    try {
      const result = await this.postProtected<Income>('docs/sale', id);
      if (result) {
        return Income.createFromObject(Income, result);
      }
      return new Income({});
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async unPost(id: number): Promise<Income> {
    try {
      const result = await this.unPostProtected<Income>('docs/sale', id);
      if (result) {
        return Income.createFromObject(Income, result);
      }
      return new Income({});
    } catch (error) {
      throw new CustomError(error.response);
    }
  }
}
