
import { ref, PropType, computed, defineComponent } from 'vue';
import { useToast } from 'primevue/usetoast';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import currency from 'currency.js';

import { SaleLine } from '@/classes/models/Sale';
import { Part } from '@/classes/models/Part';
import UNIT from '@/enums/unit';

import PartsSelector from '@/components/Incomes/PartsSelector.vue';

export default defineComponent({
  name: 'SalePartsList',
  emits: ['update:modelValue', 'update:loading', 'recalculated'],
  components: {
    Dialog,
    Button,
    DataTable,
    Column,
    InputNumber,
    Dropdown,
    'part-selector': PartsSelector,
  },
  beforeCreate() {
    if (this.$options.components) {
      this.$options.components.ProductForm = require('../Products/ProductForm.vue').default;
      this.$options.components.ProductCatalog = require('../Products/ProductCatalog').default;
    }
  },
  props: {
    modelValue: {
      type: Object as PropType<SaleLine[]>,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },

  setup(props, ctx) {
    const editingRows = ref([]);
    const multiSelector = ref(false);
    const selectedPart = ref<SaleLine>({});
    const selectedParts = ref<SaleLine[]>([]);

    const dialog = ref(false);
    const id = ref<number>();
    const editMode = ref(false);
    const update = ref<Function>();

    const units = [
      { name: 'Неизмерить', value: UNIT.NONE },
      { name: 'Шт', value: UNIT.PIECE },
    ];

    const headerStyle = 'width: 15rem';
    const toast = useToast();

    const inputVal = computed({
      get: () => props.modelValue,
      set: val => {
        ctx.emit('update:modelValue', val);
      },
    });

    const inputLoading = computed({
      get: () => props.loading,
      set: val => {
        ctx.emit('update:loading', val);
      },
    });


    // eslint-disable-next-line
    const openDialog = (event: any) => {
      dialog.value = true;
      id.value = event.id;
      editMode.value = event.editMode;
      update.value = event.update;
    };

    const checkEmptyLines = (): boolean => {
      const result = inputVal.value.some(item => !item.partId);
      if (result) {
        toast.add({
          severity: 'warn',
          summary: 'Предупреждение!',
          detail:
            'Обнаружены незаполненные товары. Для продолжения заполните или удалите пустые позиции.',
          life: 5000,
        });
        return true;
      }
      return false;
    };

    const addPart = () => {
      if (!checkEmptyLines()) {
        inputVal.value.push({
          uomId: 1,
          quantity: 1,
          pricePerUnit: 0,
          amount: 0,
          discount: 0,
          discountAmount: 0,
          totalAmount: 0,
          vatPercent: 20,
          vatAmount: 0,
        });
      }
    };

    const addMulti = () => {
      multiSelector.value = true;
    };

    const onRowContextMenu = () => {
      console.log('onRowContextMenu');
    };

    const calculateAmount = (price: number, quantity: number): number => {
      return currency(price).multiply(quantity).value;
    };

    const calculateTotalAmount = (
      amount: number,
      discount: number
    ): number => {
      return currency(amount).subtract(
        currency(amount).multiply(discount / 100).value
      ).value;
    };

    const calculateVatAmount = (
      totalAmount: number,
      vatPercent: number
    ): number => {
      return currency(totalAmount).subtract(
        currency(totalAmount).divide(1 + vatPercent / 100).value
      ).value;
    };

    const updateAmount = () => {
      inputVal.value.forEach(line => {
        const amount = calculateAmount(
          line.pricePerUnit || 0,
          line.quantity || 0
        );
        const totalAmount = calculateTotalAmount(
          amount,
          line.discount || 0
        );
        const vatAmount = calculateVatAmount(totalAmount, line.vatPercent || 0);
        if (line.pricePerUnit && line.quantity && line.discount) {
          line.discountAmount = line.pricePerUnit * line.quantity * line.discount / 100;
        }
        line.amount = amount;
        line.totalAmount = totalAmount;
        line.vatAmount = vatAmount;
      });
      ctx.emit('recalculated');
    };

    const partIsChosen = (i: number) => {
      const line = inputVal.value[i];
      line.quantity = 1;
      line.uomId = UNIT.PIECE;
      if (line.part) {
        line.partId = line.part.id;

        if (line.part.price) {
          line.pricePerUnit = line.part.price;
        } else {
          line.pricePerUnit = 0;
        }

        if (line.part.discount) {
          line.discount = line.part.discount;
        }
      }
      const amount = calculateAmount(line.pricePerUnit || 0, line.quantity);
      const totalAmount = calculateTotalAmount(
        amount,
        line.discount || 0
      );
      const vatAmount = calculateVatAmount(totalAmount, line.vatPercent || 0);
      line.amount = amount;
      line.totalAmount = totalAmount;
      line.vatAmount = vatAmount;
      ctx.emit('recalculated');
    };

    const selectedMulti = (parts: Part[]) => {
      if (parts) {
        parts.forEach(part => {
          const price = part.price || 0;
          const discount = part.discount || 0;
          const amount = calculateAmount(price, 1);
          const totalAmount = calculateTotalAmount(amount, discount);
          const vatAmount = calculateVatAmount(totalAmount, 20);
          const discountAmount = price * discount / 100;

          inputVal.value.push({
            partId: part.id,
            uomId: 1,
            part: part,
            quantity: 1,
            pricePerUnit: price,
            amount: amount,
            discount: discount,
            discountAmount: discountAmount,
            totalAmount: totalAmount,
            vatPercent: 20,
            vatAmount: vatAmount,
          });
        });
        ctx.emit('recalculated');
      }
      multiSelector.value = false;
    };

    const removeSelected = () => {
      inputVal.value = inputVal.value.filter(val => {
        return !selectedParts.value.some(part => part === val);
      });
      ctx.emit('recalculated');
    };

    const cellEditComplete = () => {
      updateAmount();
    };

    const getPartName = (part?: Part): string => {
      return part
        ? part.nomenclature
          ? `${part.nomenclature.name} ${part.marka} ${part.model}`
          : ''
        : '';
    };

    return {
      dialog,
      id,
      editMode,
      update,
      inputVal,
      inputLoading,
      units,
      headerStyle,
      selectedPart,
      selectedParts,
      editingRows,
      multiSelector,
      addPart,
      onRowContextMenu,
      cellEditComplete,
      getPartName,
      partIsChosen,
      addMulti,
      selectedMulti,
      removeSelected,
      openDialog
    };
  },
});
